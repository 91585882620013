.print-table-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: 100vh;
}

.print-table-row {
  height: 100%;
}

.print-table-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.print-table-top {
  font-size: 10px;
  font-weight: 800;
  text-align: center;
}

.print-table-client {
  font-size: 9px;
  text-align: center;
}

.print-table-logo {
  font-size: 6px;
  font-weight: 300;
  text-align: center;
}

.print-table-image {
  width: 70%;
  height: auto;
  margin-top: 1px;
  margin-bottom: 1px;
}
