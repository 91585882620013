.print-tent-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: 100vh;
}

.print-tent-row {
  height: 100%;
}

.print-tent-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.print-tent-top {
  font-weight: 600;
  text-align: center;
}

.print-tent-bottom {
  font-size: 7px;
  font-weight: 300;
  text-align: center;
}

.print-tent-image {
  width: 75%;
  height: auto;
  background-color: #fff;
}
