.print-drive-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: 100vh;
}

.print-drive-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.print-drive-top-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 50px;
}

.drive-right {
  padding-left: 80px;
}

.drive-left {
  padding-left: 40px;
}

.print-drive-bottom-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}

.print-drive-very-top {
  font-size: 32px;
  font-weight: 400;
}

.print-drive-top {
  font-size: 32px;
  font-weight: 700;
}

.print-drive-title-top {
  font-size: 35px;
  font-weight: 800;
}

.print-drive-bottom {
  font-size: 20px;
  font-weight: 400;
}

.print-drive-image {
  width: 100%;
  height: auto;
  background-color: #fff;
}
