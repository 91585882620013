.print-bom-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.print-bom-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.print-bom-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.print-bom-image {
  width: 300px;
  height: auto;
  object-fit: contain;
}
